<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="big-left">
                  <div class="top-wrapper">
                    <div class="left">
                      <div class="text" v-if="!profileIsEditable">
                        <div class="desc">
                          <div class="hey">Hey,</div>
                          <span v-if="verification_data.status !== 'approved'" v-tooltip="{
                            content: 'Please verify your profile',
                            placement: 'bottom',
                          }">
                            <router-link to="/verification"><img class="img shield" src="./../images/shield.svg"/></router-link>
                          </span>
                          <span v-if="verification_data.status === 'approved'" v-tooltip="{
                            content: 'Your profile is verified',
                            placement: 'right',
                            classes: verification_data.status === 'approved' ? 'verified' : ''
                          }">
                            <img class="img shield" src="./../images/verify.svg">
                          </span>
                          <b>{{ name }} {{ surname }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="nav">
                        <a
                          @click="goToProfilePage(item.url)"
                          v-for="item in profileNav"
                          :key="item.url"
                          :class="['desc nav__item', {'active': activeProfilePage == item.url}]"
                        >
                          <b>
                            <span>[</span>
                            {{ item.title }}
                            <span>]</span>
                          </b>
                        </a>
                        <router-link to="/contacts"
                          class='desc nav__item'
                        >
                          <b>
                            <span>[</span>
                              NEED HELP?
                            <span>]</span>
                          </b>
                        </router-link>
                      </div>
                      <div class="logout-container">
                        <a class="desc logout-desc" @click="$parent.logout">SIGN OUT</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="big-right">
                  <div class="content" v-if="activeProfilePage === 'my-work'">
                    <!-- <div class="tab-list">
                      <select v-model="activeMyProductsTab">
                        <option v-for="(item, i) in myProductsTabs" :value="item.title" :key="i">{{ item.title }}</option>
                      </select>
                    </div> -->
                    <div class="title title-big regular">
                      MY ORDERS
                      <a class="desc desc-new" @click="$parent.goToPromtCreate">
                        Create new
                      </a>
                    </div>
                    <!-- <div class="title">{{  createdHistory  }}</div>
                    <div class="title">{{  createdHistoryImage  }}</div> -->
                    <div class="content-wrapper">
                      <div class="table-container table-container-depositHistory table-container-createdHistory" v-if="activeMyProductsTab === 'Art'">
                        <div class="table">
                          <table>
                            <!-- <thead>
                              <tr v-if="createdHistoryImage && createdHistoryImage.length">
                                <td>
                                  <div class="td-wrapper">Design</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Name</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Fiters</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Price</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Status</div>
                                </td>
                              </tr>
                              <tr v-else>
                                <td></td>
                              </tr>
                            </thead> -->
                            <tbody v-if="createdHistoryImage && !createdHistoryImage.length">
                              <tr>
                                <td class="bold">Empty</td>
                              </tr>
                            </tbody>
                            <tbody v-if="createdHistoryImage && createdHistoryImage.length">
                              <tr v-for="(item, i) in createdHistoryImage" :key="i">
                                <td><div class="td-wrapper">
                                  <div class="img-container">
                                    <img v-if="item.doc_type == 'image'" class="img" :src="$parent.imgDomain + item.doc_url" />
                                  </div>
                                </div></td>
                                <td>
                                  <div class="td-wrapper">
                                    {{item.title}}
                                  </div>
                                </td>
                                <td><div class="td-wrapper td-wrapper-tags" v-if="item.category_id && categoryOptions && categoryOptions.length">
                                  <span>{{categoryOptions.find(obj => obj.id === item.category_id).title}}</span>
                                </div></td>
                                <td class="price"><div class="td-wrapper price">{{ item.user_price }} <InternalCurrency/></div></td>
                                <td>
                                  <div class="td-wrapper offer-link" v-if="item.status == 'offer'" @click="openOffer(item)">
                                    <span>Offer</span>
                                  </div>
                                  <div class="td-wrapper" v-else>
                                    <span class="status">{{ item.status }}</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory" v-if="activeMyProductsTab === 'Text'">
                        <div class="table">
                          <table>
                            <!-- <thead>
                              <tr v-if="createdHistoryText && createdHistoryText.length">
                                <td>
                                  <div class="td-wrapper">Design</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Name</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Description</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Fiters</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Price</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Status</div>
                                </td>
                              </tr>
                              <tr v-else>
                                <td></td>
                              </tr>
                            </thead> -->
                            <tbody v-if="createdHistoryText && !createdHistoryText.length">
                              <tr>
                                <td class="bold">Empty</td>
                              </tr>
                            </tbody>
                            <tbody v-if="createdHistoryText && createdHistoryText.length">
                              <tr v-for="(item, i) in createdHistoryText" :key="i">
                                <td>
                                  <div class="td-wrapper">
                                    <div v-if="item.doc_type == 'doc'" class="doc-img"><img src='./../images/doc.svg' class="image"/></div>
                                  </div>
                                </td>
                                <td><div class="td-wrapper">
                                  {{item.title}}
                                </div></td>
                                <td>
                                  <div class="td-wrapper">
                                    {{item.description}}
                                  </div>
                                </td>
                                <td><div class="td-wrapper" v-if="item.category_id && categoryOptions && categoryOptions.length">
                                  <span>{{categoryOptions.find(obj => obj.id === item.category_id).title}}</span>
                                </div></td>
                                <td class="price"><div class="td-wrapper price">{{ item.user_price }} {{$parent.currency}}</div></td>
                                <td>
                                  <div class="td-wrapper offer-link" v-if="item.status == 'offer'" @click="openOffer(item)">Offer</div>
                                  <div class="td-wrapper" v-else>
                                    <span class="status">{{ item.status }}</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'my-orders'">
                    <div class="content-wrapper">
                      <div class="title title-big regular">MY ORDERS</div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory">
                        <div class="table">
                          <table>
                            <!-- <thead>
                              <tr v-if="$parent.orderHistory && $parent.orderHistory.length">
                                <td>
                                  <div class="td-wrapper">Purchase</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Name</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Fiters</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Price</div>
                                </td>
                                <td>
                                  <div class="td-wrapper"></div>
                                </td>
                              </tr>
                              <tr v-else>
                                <td></td>
                              </tr>
                            </thead> -->
                            <tbody v-if="$parent.orderHistory && !$parent.orderHistory.length">
                              <tr>
                                <td class="bold">Unfortunately, you don't have a purchase history yet.</td>
                              </tr>
                            </tbody>
                            <tbody v-for="(item, i) in $parent.orderHistory" :key="i">
                              <tr v-for="(itemPart, i) in item.items" :key="i">
                                <td><div class="td-wrapper">
                                  <div class="img-container">
                                    <img v-if="itemPart.info.product_type == 'image'" class="img" :src="$parent.imgDomain + itemPart.info.doc_url" />
                                    <div v-else class="doc-img">
                                      <img src='./../images/doc.svg' class="image"/>
                                    </div>
                                  </div>
                                </div></td>
                                <td>
                                  <div class="td-wrapper">
                                    {{itemPart.info.title}}
                                  </div>
                                </td>
                                <td>
                                  <div class="td-wrapper td-wrapper-tags"
                                       v-if="itemPart.info.tags && itemPart.info.tags.length">
                                    <v-popover v-if="i === 0 && itemPart.info.image_generated ">
                                         <span slot="popover" :class="[{prompt: itemPart.info.image_generated}]"
                                               @click="prompt(itemPart)"
                                               v-for="(item, i) in itemPart.info.tags"
                                               :key="i">{{ item.name }}</span>
                                      <span v-clipboard="item.name" :class="[{prompt: itemPart.info.image_generated}]"
                                            v-for="(item, i) in itemPart.info.tags"
                                            :key="i">{{
                                          i === 0 && itemPart.info.image_generated ? 'prompt' : item.name
                                        }}</span>
                                    </v-popover>
                                    <span v-else :class="[{prompt: itemPart.info.image_generated}]"
                                          @click="prompt(itemPart)" v-for="(item, i) in itemPart.info.tags"
                                          :key="i">{{
                                        i === 0 && itemPart.info.image_generated ? 'prompt' : item.name
                                      }}</span>
                                  </div>
                                </td>
                                <td class="price"><div class="td-wrapper price">{{ itemPart.price }} <InternalCurrency/></div></td>
                                <td>
                                  <div class="td-wrapper td-wrapper-btns">
                                    <div class="options">
                                      <select v-if="resizeOptions" v-model="activeDownloadSize">
                                        <option v-for="(item, i) in resizeOptions" :key="i">{{ item }}</option>
                                      </select>
                                      <select v-if="formatOptions" v-model="activeDownloadFormat">
                                        <option v-for="(item, i) in formatOptions" :key="i">{{ item }}</option>
                                      </select>
                                    </div>
                                    <div class="button blue" @click="downloadProduct(itemPart.info)">
                                      DOWNLOAD
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'payments-history'">
                    <div class="title title-big regular">PAYMENTS HISTORY</div>
                    <div class="content-wrapper withdraw-content-wrapper main-content-wrapper">
                      <div class="table-container table-container-depositHistory">
                        <div class="table">
                          <table>
                            <!-- <thead>
                              <tr v-if="$parent.depositHistory && $parent.depositHistory.length">
                                <td>
                                  <div class="td-wrapper">Date</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Type</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Sum</div>
                                </td>
                                <td>
                                  <div class="td-wrapper">Status</div>
                                </td>
                              </tr>
                              <tr v-else>
                                <td></td>
                              </tr>
                            </thead> -->
                            <tbody v-if="$parent.depositHistory && !$parent.depositHistory.length">
                              <tr>
                                <td class="bold">Unfortunately, you don't have a purchase history yet.</td>
                              </tr>
                            </tbody>
                            <tbody v-if="$parent.depositHistory && $parent.depositHistory.length">
                              <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                                <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                                <td>
                                  <div class="td-wrapper">
                                    {{item.type}}
                                  </div>
                                </td>
                                <td class="price"><div class="td-wrapper price">{{item.sum}} {{item.currencyCode}}</div></td>
                                <td><div class="td-wrapper price">{{ Math.round(item.points) }} RO</div></td>
                                <td><div class="td-wrapper">
                                  <span
                                    :class="['status',
                                    {'red': item.status === 'Error'},
                                    {'green': item.status === 'Success'}
                                    ]"
                                    >{{item.status}}</span>
                                </div></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="upload-button button" @click="$parent.goToPromtCreate">
                      <img class="img" src="./../img/upload.svg"/>
                      <div class="desc">Загрузить файлы</div>
                    </div> -->
                  </div>
                  <div class="content" v-if="activeProfilePage === 'my-details'">
                    <div class="content-wrapper">
                      <div class="table-container personal-info">
                        <div class="table-container-wrapper">
                          <div class="title title-big regular">MY ACCOUNT DETAILS</div>
                          <div class="input-fields">
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Name:</div>
                              <input type="text" placeholder="Введите имя" v-model="name"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Surname:</div>
                              <input type="text" placeholder="Введите Фамилию" v-model="surname"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Email:</div>
                              <input type="email" placeholder="Электронная почта" v-model="email"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span>Phone:</div>
                              <input type="number" placeholder="Phone" v-model="phone"/>
                            </div>
                            <button class="button" @click="updateProfileData">
                              <span>[ SAVE CHANGES ]</span>
                            </button>
                            <transition name="fade">
                              <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                            </transition>
                            <transition name="fade">
                              <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                            </transition>
                          </div>
                        </div>
                      </div>
                      <div class="table-container password">
                        <div class="table-container-wrapper">
                          <div class="title title-big regular">CHANGE PASSWORD</div>
                          <div class="input-fields">
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Old password:</div>
                              <input type="text" placeholder="Введите старый пароль" v-model="curPass"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> New password:</div>
                              <input type="text" placeholder="Введите новый пароль" v-model="newPass"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Confirm password:</div>
                              <input type="text" placeholder="Повторите новый пароль" v-model="repeatPass"/>
                            </div>
                            <button class="button" @click="updatePassword">
                              <span>[ SAVE CHANGES ]</span>
                            </button>
                            <transition name="fade">
                              <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                            </transition>
                            <transition name="fade">
                              <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>

<script>
  import Nav from '../components/Nav.vue'
  import VTooltip from "v-tooltip";
  import Vue from 'vue'
  import InternalCurrency from "@/components/InternalCurrency.vue";
  Vue.use(VTooltip);
export default {
  name: 'Profile',
  props: ['orderHistory', 'userName', 'createdHistory', 'categories'],
  components: {
    InternalCurrency,
    Nav
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      website: '',
      fb_url: '',
      ig_url: '',
      tt_url: '',
      joined: '',
      balance: '',
      avatar_path: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      withdrawName: '',
      withdrawEmail: '',
      profileNav: [
        {
          url: 'my-orders',
          title: 'MY ORDERS'
        },
        {
          url: 'my-details',
          title: 'MY DETAILS'
        },
        {
          url: 'my-work',
          title: 'MY WORK'
        },
        {
          url: 'payments-history',
          title: 'PAYMENTS HISTORY'
        }
      ],
      myProductsTabs: [
        {
          page: 'art',
          title: 'Art'
        },
        {
          page: 'text',
          title: 'Text'
        }
      ],
      settingsTabs: [
        {
          page: 'personal',
          title: 'Персональная информация'
        },
        {
          page: 'password',
          title: 'Смена пароля'
        }
      ],
      transactionTabs: [
        {
          page: 'deposits',
          title: 'Пополнение'
        },
        {
          page: 'sell',
          title: 'Продажа'
        }
      ],
      activeTransactionTab: 'deposits',
      activeMyProductsTab: 'Art',
      activeSettingsTab: 'personal',
      categoryOptions: [],
      profileIsEditable: false,
      createdHistoryImage: [],
      createdHistoryText: [],
      verification_data: '',
      userBalance: '',
      activeDownloadSize: '',
      activeDownloadFormat: '',
      resizeOptions: [],
      formatOptions: []
    }
  },
  watch: {
    userName: function () {
			this.getProfileData();
		},
    createdHistory: function (newArr) {
      this.createdHistoryImage = newArr.filter(item => item.doc_type == 'image');
      this.createdHistoryText = newArr.filter(item => item.doc_type == 'doc');
		},
  },
  mounted() {
    this.activeProfilePage = this.$route.params.page;
    this.getProfileData();
    this.getCategories();
    this.getDownloadOptions();

    console.log(this.$parent.createdHistory, 'history');

    this.createdHistoryImage = this.$parent.createdHistory.filter(item => item.doc_type == 'image');
    this.createdHistoryText = this.$parent.createdHistory.filter(item => item.doc_type == 'text');
    this.getOfferHistory();
  },
  methods: {
    copy(text) {
      window.navigator.clipboard.writeText(text);
    },
    prompt(item) {
      if (item.info.image_generated) {
        console.log('Hudfdfd');
      }
    },
    openOffer(item) {
      this.$emit('openOffer', item)
    },
    getOfferHistory() {
      this.$http.get(process.env.VUE_APP_API + 'offer/history')
      .then((res) => {
          console.log(res);

      })
      .catch(() => {

      })
    },
    getDownloadOptions() {
      this.$http.get(process.env.VUE_APP_API + 'download-options')
      .then((res) => {
        this.resizeOptions = res.data.payload.resize_options;
        this.formatOptions = res.data.payload.format_options;
        this.activeDownloadSize = res.data.payload.resize_options[0];
        this.activeDownloadFormat = res.data.payload.format_options[0];
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    deletePrompt(id) {
      this.$emit('deactivateCreatedItem', id);
    },
    downloadProduct(product) {
      this.$http.get(process.env.VUE_APP_API + 'user/media/download', {
        params: {
          id: product.id,
          size: this.activeDownloadSize,
          format: this.activeDownloadFormat
        },
        responseType: 'blob' // Указываем, что ожидаем бинарные данные
      })
          .then((res) => {
            // Создаем ссылку для скачивания
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;

            // Определяем имя файла из заголовков или используем дефолтное
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'download';
            if (contentDisposition && contentDisposition.includes('filename=')) {
              fileName = contentDisposition
                  .split('filename=')[1]
                  .split(';')[0]
                  .replace(/['"]/g, ''); // Убираем кавычки, если они есть
            }

            link.setAttribute('download', fileName); // Устанавливаем имя файла
            document.body.appendChild(link);
            link.click(); // Программно "кликаем" по ссылке для скачивания

            // Очищаем ссылку после скачивания
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          })
          .catch((error) => {
            // Обработка ошибок
            if (error.response && error.response.data && error.response.data.message) {
              this.$emit('setNewError', error.response.data.message);
            } else {
              this.$emit('setNewError', 'Failed to download the file. Please try again.');
            }
          });
    },
    editProfile() {
      if (this.profileIsEditable === true) {
        this.updateProfileData();
        this.profileIsEditable = false;
      } else {
        this.profileIsEditable = true;
      }

    },
    getFilenameFromContentDisposition(contentDisposition) {
      const match = /filename\*=UTF-8''([^;]*)/.exec(contentDisposition) || /filename="([^"]*)"/.exec(contentDisposition);
      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      } else {
        return 'downloaded-file';
      }
    },
    saveFile(blob, filename) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
      })
      .catch(() => {

      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        // "nickname": this.nickname,
        // "web_url": this.website,
        // "fb_url": this.fb_url,
        // "ig_url": this.ig_url,
        // "tt_url": this.tt_url
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    },
    setActiveMyProductsTab(page) {
      this.activeMyProductsTab = page;
    },
    setActiveTransactionTab(page) {
      this.activeTransactionTab = page;
    },
    setSettingsActiveTab(page) {
      this.activeSettingsTab = page;
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.joined = res.data.created_at;
          this.balance = res.data.balance;
          this.nickname = res.data.nickname;
          this.website = res.data.web_url;
          this.fb_url = res.data.fb_url;
          this.ig_url = res.data.ig_url;
          this.tt_url = res.data.tt_url;
          this.avatar_path = res.data.avatar_path;
          this.verification_data = res.data.verification_data;
          this.userBalance = res.data.balance;

      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    editPrompt(item) {
      this.$emit('editPrompt', item);
    }
  }
}
</script>