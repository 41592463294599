<template>
  <div class="menu">
    <Nav/>
    <div class="header-profile header-profile-mob" v-if="$parent.isAuth">
      <a @click.prevent="$parent.openTopUpModal()" class="button cart topup-balance">
        <img class="img" src="./../images/top_up.svg"/>
        <div v-if="$parent.cartContents && $parent.cartContents.length">
          RO {{ $parent.userBalance }}
        </div>
      </a>
      <router-link class="profile-desc" to="/profile/my-orders">
        <div class="icon">
          <img class="img" src="./../images/user.svg"/>
        </div>
      </router-link>
    </div>
    <div v-else>
      <a class="desc link" @click="$emit('openSignInModal')">
        <b>SIGN IN</b>
        <img src="./../images/link.svg" class="img"/>
      </a>
    </div>
    <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
      <select class="select currency-select" v-model="curr"
              @change="selectCurr()"
      >
        <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue'

export default {
  name: 'Menu',
  props: ['currencyCode', 'currencyValue', 'categories'],
  data: function () {
    return {
      curr: ''
    }
  },
  components: {
    Nav
  },
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    scrollToSection(section) {
      this.$parent.closeMenu();
      this.$emit('scrollToSection', section)
    },
    selectCurr() {
      this.changeCurrency(this.curr)
      localStorage.setItem("currency", this.curr);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
  },
}
</script>
