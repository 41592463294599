import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from 'axios'
import './vee-validate';
Vue.prototype.$http = axios
import VTooltip from 'v-tooltip'
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)
Vue.use(VTooltip)

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import './../node_modules/slick-carousel/slick/slick.css';
import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

Vue.prototype.$http.defaults.withCredentials = true;

new Vue({
	router:router,
	render: h => h(App),
}).$mount('#app')
