<template>
  <div class="modal filter-modal promt-create-modal offer-modal">
    <div class="overlay" @click="$parent.closeOfferModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <img class="close" @click="$parent.closeOfferModal" src="./../images/close.svg"/>
                <div class="title">Your Offer</div>
              </div>
              <div class="bottom">
                <div class="input-container">
                  <div class="desc">Name of product:</div>
                  <input readonly type="text" v-model="name"/>
                </div>
                <div class="desc">Fiters:</div>
                <div class="list">
                  <div class="button tag" v-for="(item,i) in tags" :key="i">{{ item.name }}</div>
                </div>
                <div class="price-list">
                  <div class="col">
                    <div class="desc">Your price:</div>
                    <div class="input-container input-container-price">
                      <input readonly type="number" v-model="yourPrice"/>
                      <div class="desc">RO</div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="desc">Our price:</div>
                    <div class="input-container input-container-price our">
                      <input readonly type="number" v-model="ourPrice"/>
                      <div class="desc">RO</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button class="button gray" @click="decline">
                  <span>[ DECLINE ]</span>
                </button>
                <button class="button" @click="accept">
                  <span>[ ACCEPT ]</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OfferModal',
  props: [],
  components: {
  },
  data: function() {
    return {
     name: '',
     yourPrice: '',
     ourPrice: '',
     tags: [],
     verificationStatus: null,
    }
  },
  mounted() {
    this.name = this.$parent.activeOffer.title;
    this.yourPrice = this.$parent.activeOffer.user_price;
    this.ourPrice = this.$parent.activeOffer.service_price;
    this.tags = this.$parent.activeOffer.tags;
    this.fetchVerificationStatus();
  },
  methods: {
    fetchVerificationStatus() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          this.verificationStatus = res.data.verification_data.status;
        })
        .catch((error) => {
          console.error("Error fetching verification_data:", error);
        })
      },
    decline() {
      let data = {
        'id': this.$parent.activeOffer.id
      }
      this.$http.post(process.env.VUE_APP_API + 'offer/decline', data)
      .then(() => {
        this.$parent.closeOfferModal();
        this.$parent.openNoDealModal();
        this.$parent.getCreatedHistory();
      })
      .catch(() => {

      })
    },
    accept() {
      if (parseFloat(this.ourPrice, 3) >= parseFloat(150) && this.verificationStatus !== 'approved') {
        this.$parent.closeOfferModal();
        this.$parent.openVerifyModal();
      }
      else {
        this.$parent.openAcceptOfferModal();
      }
    },
  }
}
</script>