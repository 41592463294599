<script>
export default {
  name: "InternalCurrency"
}
</script>

<template>
  <span class="currency">RO</span>
</template>

<style scoped lang="scss">

</style>