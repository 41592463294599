import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import ProductList from './pages/ProductList.vue'
import Profile from './pages/Profile.vue'
import ProductPage from './pages/ProductPage.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import Faq from './pages/Faq.vue'
import Verification from './pages/Verification.vue'
import WithdrawConfirm from './pages/WithdrawConfirm.vue'
import WithdrawFail from './pages/WithdrawFail.vue'
import WithdrawSuccess from './pages/WithdrawSuccess.vue'
import Login from './pages/Login.vue'
import Register from './pages/Register.vue'
import Cart from './pages/Cart.vue'
import Recover from './pages/Recover.vue'
import Contacts from './pages/Contacts.vue'
import Video from './pages/Video.vue'
import GenerateImage from "@/pages/GenerateImage.vue";


Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes: [
		//{ path: '/', redirect: '/home' },
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/register',
			name: 'Register',
			component: Register
		},
		{
			path: '/recover',
			name: 'Recover',
			component: Recover
		},
		{
			path: '/product-list/:type',
			name: 'ProductList',
			component: ProductList
		},
		{
			path: '/profile/:page',
			name: 'Profile',
			component: Profile
		},
		{
			path: '/products/:id',
			name: 'ProductPage',
			component: ProductPage
		},
		{
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
			path: '/cart',
			name: 'Cart',
			component: Cart
		},
		{
			path: '/faq',
			name: 'Faq',
			component: Faq
		},
		{
			path: '/contacts',
			name: 'Contacts',
			component: Contacts
		},
		{
			path: '/video',
			name: 'Video',
			component: Video
		},
		{
			path: '/verification',
			name: 'Verification',
			component: Verification
		},
		{
			path: '/pages/:id',
			name: 'TextPage',
			component: TextPage
		},
		{
			path: '/withdraw-confirm',
			name: 'WithdrawConfirm',
			component: WithdrawConfirm
		},
		{
			path: '/withdraw-fail',
			name: 'WithdrawFail',
			component: WithdrawFail
		},
		{
			path: '/withdraw-success',
			name: 'WithdrawSuccess',
			component: WithdrawSuccess
		},
		{
			path: '/generate-image',
			name: 'GenerateImage',
			component: GenerateImage,
			meta: {requiresAuth: true}
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!window.localStorage.getItem('auth')) {
			// Проверка авторизации (можно заменить на store или API-запрос)
			next('/login'); // Перенаправляем на логин, если не авторизован
		} else {
			next(); // Продолжаем маршрут
		}
	} else {
		next(); // Если авторизация не требуется, продолжаем
	}
});


export default router;