<template>
  <main class="main generate-image-page profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="generate-image-form">
            <div class="generate-image-form-wrapper">
              <div class="wrapper">
<!--                <div class="big-left">-->
<!--                  <div class="top-wrapper">-->
<!--                    <div class="left">-->
<!--                      <div class="text" v-if="!profileIsEditable">-->
<!--                        <div class="desc">-->
<!--                          <div class="hey">Hey,</div>-->
<!--                          <span v-if="verification_data.status !== 'approved'" v-tooltip="{-->
<!--                            content: 'Please verify your profile',-->
<!--                            placement: 'bottom',-->
<!--                          }">-->
<!--                            <router-link to="/verification"><img class="img shield" src="./../images/shield.svg"/></router-link>-->
<!--                          </span>-->
<!--                          <span v-if="verification_data.status === 'approved'" v-tooltip="{-->
<!--                            content: 'Your profile is verified',-->
<!--                            placement: 'right',-->
<!--                            classes: verification_data.status === 'approved' ? 'verified' : ''-->
<!--                          }">-->
<!--                            <img class="img shield" src="./../images/verify.svg">-->
<!--                          </span>-->
<!--                          <b>{{ name }} {{ surname }}</b>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="right">-->
<!--                      <div class="nav">-->
<!--                        <a-->
<!--                            @click="goToProfilePage(item.url)"-->
<!--                            v-for="item in profileNav"-->
<!--                            :key="item.url"-->
<!--                            :class="['desc nav__item', {'active': activeProfilePage == item.url}]"-->
<!--                        >-->
<!--                          <b>-->
<!--                            <span>[</span>-->
<!--                            {{ item.title }}-->
<!--                            <span>]</span>-->
<!--                          </b>-->
<!--                        </a>-->
<!--                        <router-link to="/contacts"-->
<!--                                     class='desc nav__item'-->
<!--                        >-->
<!--                          <b>-->
<!--                            <span>[</span>-->
<!--                            NEED HELP?-->
<!--                            <span>]</span>-->
<!--                          </b>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                      <div class="logout-container">-->
<!--                        <a class="desc logout-desc" @click="$parent.logout">SIGN OUT</a>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                  <div class="content">
                    <div class="content-wrapper">
                      <div class="title title-big regular">GENERATE IMAGE</div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory">
                        <div class="table">
                          <ValidationObserver v-slot="{ invalid, pristine, handleSubmit }" slim>
                            <form @submit.prevent="handleSubmit(submit)" class="wrapper">
                              <div class="container">
                                <div class="form-wrapper">
                                  <ValidationProvider name="title" v-slot="{ invalid, dirty, errors }" rules="required" slim>
                                    <div class="input-container">
                                      <input type="text" :class="{error: invalid && dirty}" placeholder="Enter title" v-model="title"/>

                                      <div v-if="errors && errors.length" class="error">{{ errors[0] }}</div>
                                    </div>
                                  </ValidationProvider>
                                  <ValidationProvider name="prompt" v-slot="{ invalid, dirty, errors }" rules="required" slim>
                                    <div class="input-container">
                                      <textarea :class="{error: invalid && dirty}" placeholder="Your request" v-model="prompt"/>
                                      <div v-if="errors && errors.length" class="error">{{ errors[0] }}</div>
                                    </div>
                                  </ValidationProvider>

                                  <transition name="fade">
                                    <div class="desc red" v-if="error">{{ error }}</div>
                                  </transition>

                                  <div class="buttons">
                                    <button :class="['button', 'blue', {loading: submitting}]" type="submit" :disabled="invalid || pristine || submitting">
                                      <div class="spinner"></div>
                                      <span>Generate</span>
                                    </button>

                                    <div class="rate">
                                      {{ $parent.settings.image_generation_price }} RO = 1 generation
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </ValidationObserver>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>

<script>
import Nav from '../components/Nav.vue'
import VTooltip from "v-tooltip";
import Vue from 'vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
Vue.use(VTooltip);

export default {
  name: 'Profile',
  props: ['orderHistory', 'userName', 'createdHistory', 'categories'],
  components: {
    Nav, ValidationObserver, ValidationProvider
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      website: '',
      fb_url: '',
      ig_url: '',
      tt_url: '',
      joined: '',
      balance: '',
      avatar_path: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: 'my-orders',
      withdrawName: '',
      withdrawEmail: '',
      submitting: false,
      error: null,
      profileNav: [
        {
          url: 'my-orders',
          title: 'MY ORDERS'
        },
        {
          url: 'my-details',
          title: 'MY DETAILS'
        },
        {
          url: 'my-work',
          title: 'MY WORK'
        },
        {
          url: 'payments-history',
          title: 'PAYMENTS HISTORY'
        }
      ],
      myProductsTabs: [
        {
          page: 'art',
          title: 'Art'
        },
        {
          page: 'text',
          title: 'Text'
        }
      ],
      settingsTabs: [
        {
          page: 'personal',
          title: 'Персональная информация'
        },
        {
          page: 'password',
          title: 'Смена пароля'
        }
      ],
      transactionTabs: [
        {
          page: 'deposits',
          title: 'Пополнение'
        },
        {
          page: 'sell',
          title: 'Продажа'
        }
      ],
      activeTransactionTab: 'deposits',
      activeMyProductsTab: 'Art',
      activeSettingsTab: 'personal',
      categoryOptions: [],
      profileIsEditable: false,
      createdHistoryImage: [],
      createdHistoryText: [],
      verification_data: '',
      userBalance: '',
      activeDownloadSize: '',
      activeDownloadFormat: '',
      resizeOptions: [],
      formatOptions: [],
      amount: null,
      title: '',
      prompt: ''
    }
  },
  watch: {
    userName: function () {
      this.getProfileData();
    },
    createdHistory: function (newArr) {
      this.createdHistoryImage = newArr.filter(item => item.doc_type == 'image');
      this.createdHistoryText = newArr.filter(item => item.doc_type == 'doc');
    },
  },
  mounted() {
    // this.activeProfilePage = this.$route.params.page;
    this.getProfileData();
    this.getCategories();
    this.getDownloadOptions();
    this.createdHistoryImage = this.$parent.createdHistory.filter(item => item.doc_type == 'image');
    this.createdHistoryText = this.$parent.createdHistory.filter(item => item.doc_type == 'text');
    this.getOfferHistory();
  },
  methods: {
    async submit() {
      this.submitting = true;

      try {
        await this.$http.post(process.env.VUE_APP_API + 'image/generate', {
          title: this.title,
          prompt: this.prompt
        });

        this.error = null;

        await this.$router.push('/profile/my-orders');
      } catch (e) {
        if (e.response.data.status === 'ERROR' && e.response.data.message) {
          this.error = e.response.data.message;
        }
      } finally {
        this.submitting = false;
      }
    },
    openOffer(item) {
      this.$emit('openOffer', item)
    },
    getOfferHistory() {
      this.$http.get(process.env.VUE_APP_API + 'offer/history')
          .then((res) => {
            console.log(res);

          })
          .catch(() => {

          })
    },
    getDownloadOptions() {
      this.$http.get(process.env.VUE_APP_API + 'download-options')
          .then((res) => {
            this.resizeOptions = res.data.payload.resize_options;
            this.formatOptions = res.data.payload.format_options;
            this.activeDownloadSize = res.data.payload.resize_options[0];
            this.activeDownloadFormat = res.data.payload.format_options[0];
          })
          .catch((res) => {
            this.$emit('setNewError', res.response.data.message)
          })
    },
    deletePrompt(id) {
      this.$emit('deactivateCreatedItem', id);
    },
    downloadProduct(product) {
      this.$http.get(process.env.VUE_APP_API + 'user/media/download?id=' + product.id + '&size=' + this.activeDownloadSize + '&format=' + this.activeDownloadFormat)
          .then((res) => {
            window.location = res.config.url;
          })
          .catch((res) => {
            this.$emit('setNewError', res.response.data.message)
          })
    },
    editProfile() {
      if (this.profileIsEditable === true) {
        this.updateProfileData();
        this.profileIsEditable = false;
      } else {
        this.profileIsEditable = true;
      }

    },
    getFilenameFromContentDisposition(contentDisposition) {
      const match = /filename\*=UTF-8''([^;]*)/.exec(contentDisposition) || /filename="([^"]*)"/.exec(contentDisposition);
      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      } else {
        return 'downloaded-file';
      }
    },
    saveFile(blob, filename) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
          .then((res) => {
            this.categoryOptions = res.data.payload;
          })
          .catch(() => {

          })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        // "nickname": this.nickname,
        // "web_url": this.website,
        // "fb_url": this.fb_url,
        // "ig_url": this.ig_url,
        // "tt_url": this.tt_url
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
          .then((res) => {
            if (res.data.status == "OK") {
              this.profileUpdateSuccessMessaage = 'Success';
              setTimeout(() => {
                this.profileUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.profileUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.profileUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
          .then((res) => {
            if (res.data.status == "OK") {
              this.passUpdateSuccessMessaage = 'Success';
              this.curPass = '',
                  this.newPass = '',
                  this.repeatPass = '';
              setTimeout(() => {
                this.passUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.passUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.passUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    },
    setActiveMyProductsTab(page) {
      this.activeMyProductsTab = page;
    },
    setActiveTransactionTab(page) {
      this.activeTransactionTab = page;
    },
    setSettingsActiveTab(page) {
      this.activeSettingsTab = page;
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
          .then((res) => {
            this.email = res.data.email;
            this.name = res.data.name;
            this.surname = res.data.surname;
            this.phone = res.data.phone;
            this.joined = res.data.created_at;
            this.balance = res.data.balance;
            this.nickname = res.data.nickname;
            this.website = res.data.web_url;
            this.fb_url = res.data.fb_url;
            this.ig_url = res.data.ig_url;
            this.tt_url = res.data.tt_url;
            this.avatar_path = res.data.avatar_path;
            this.verification_data = res.data.verification_data;
            this.userBalance = res.data.balance;

          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.$parent.openSignInModal();
            }
          })
    },
    editPrompt(item) {
      this.$emit('editPrompt', item);
    }
  }
}
</script>